ko.subscribable.fn.subscribeChanged = function (callback) {
    var oldValue;
    this.subscribe(function(_oldValue) {
        oldValue = _oldValue;
    }, this, "beforeChange");

    this.subscribe(function(newValue) {
        callback(newValue, oldValue);
    });
};

ko.subscribable.fn.subscribeIfChanged = function(callback) {
    var oldValue;
    this.subscribe(function(_oldValue) {
        oldValue = _oldValue;
    }, this, "beforeChange");

    var _valueOrNull = function(value) {
        if (value === null || value === undefined || value === "") {
            return null;
        } else {
            return value;
        }
    };

    this.subscribe(function(newValue) {
        if (_valueOrNull(newValue) != _valueOrNull(oldValue)) {
            callback(newValue, oldValue);
        }
    });
};

ko.subscribable.fn.subscribeIfDateChanged = function(callback) {
    var oldValue;
    this.subscribe(function(_oldValue) {
        oldValue = _oldValue;
    }, this, "beforeChange");

    var _valueOrNull = function(value) {
        if (value === null || value === undefined || value === "") {
            return null;
        } else {
            return value;
        }
    };

    this.subscribe(function(newValue) {
        if (_valueOrNull(newValue) != _valueOrNull(oldValue)) {
            if (!moment(newValue).isSame(oldValue)) {
                callback(newValue, oldValue);
            }
        }
    });
};

ko.bindingHandlers.tableRowSlideVisible = {
    init: function(element, valueAccessor) {
        //Don't animate on init - just hide/show
        $(element).toggle(ko.unwrap(valueAccessor()));
    },
    update: function(element, valueAccessor) {
        var $tableRow = $(element);
        var animationSpeed = 250;

        var visible = ko.unwrap(valueAccessor());
        if (visible) {
            $tableRow.show();
        } else {
            //Store padding before animation
            $tableRow.data("original-padding", $tableRow.children("td").first().css("padding-top"));

            //Animate removal of vertical padding from table cells
            $tableRow.
                children("td").
                animate({
                    paddingTop: 0,
                    paddingBottom: 0
                }, animationSpeed);

            //Animate slide up of table row
            $tableRow.
                children("td").
                //Tables are not animatable so we must wrap the contents in a div which we can animate 
                wrapInner("<div class=\"animation-wrapper\" />").
                children(".animation-wrapper").
                slideUp(animationSpeed, function() {
                    $tableRow.hide();
                    setTimeout(function() {
                        $tableRow.remove();
                    }, animationSpeed);
                }
            );
        }
    }
};

ko.bindingHandlers.cssClass = {
    update: function(element, valueAccessor) {
        if (element["__ko__previousClassValue__"]) {
            ko.utils.toggleDomNodeCssClass(element, element["__ko__previousClassValue__"], false);
        }
        var value = ko.utils.unwrapObservable(valueAccessor());
        ko.utils.toggleDomNodeCssClass(element, value, true);
        element["__ko__previousClassValue__"] = value;
    }
};


ko.bindingHandlers.highlight2 = {
  
    update: function(element, valueAccessor) {
        var options = valueAccessor();
        var value = ko.utils.unwrapObservable(options.text);
        var search = ko.utils.unwrapObservable(options.highlight);
        var css = ko.utils.unwrapObservable(options.css);
        if (options.sanitize) {
            value = $('<div/>').text(value).html(); //could do this or something similar to escape HTML before replacement, if there is a risk of HTML injection in this value
        }
        var replacement = '<mark>' + search + '</mark>';
        element.innerHTML = value.replace(new RegExp(search, 'g'), replacement);
    }
};


ko.bindingHandlers.highlight = {
    update: function(element, valueAccessor, allBindings, viewModel) {
        var filterTerm = typeof valueAccessor() === "object" ? valueAccessor().value : valueAccessor();

        function escapeRegExp(string) {
            return string.replace(/([.*+?^=!:${}()|\[\]\/\\])/ig, "\\$1");
        }

        function replaceAll(string, find) {
            var regex;
            var filterString = escapeRegExp(find);
            if (typeof valueAccessor() === "object" && valueAccessor().startsWith) {
                if (valueAccessor().people) {
                    var title = viewModel.titleName ? viewModel.titleName + " " : "";
                    string = viewModel.firstName + " " + viewModel.lastName;
                    regex = new RegExp("(\\s)?" + filterString, "ig");
                    var  returnString =  title + $('<div/>').text(string).html().replace(regex, function($0, $1, $2) {
                        if ($2 === 0) return "<mark>" + $0 + "</mark>";
                        return $1 ? " <mark>" + $.trim($0) + "</mark>" : $0;
                    });
                    return  returnString;
                } else {
                    regex = new RegExp("(^" + filterString + ")", "i");
                      var returnString =$('<div/>').text(string).html().replace(regex, "<mark>$1</mark>")
                    return  returnString;
                }
            } else {
                regex = new RegExp("(" + filterString + ")", "ig");
                  var returnString =$('<div/>').text(string).html().replace(regex, "<mark>$1</mark>")
                return  returnString ;
            }
        }

        if (filterTerm) {
            filterTerm = filterTerm.trim();
            element.innerHTML = replaceAll(element.innerText, _.escape(filterTerm));
        }
    }
};

ko.bindingHandlers.href = {
    update: function(element, valueAccessor) {
        ko.bindingHandlers.attr.update(element, function() {
            return { href: valueAccessor() };
        });
    }
};

ko.bindingHandlers.src = {
    update: function(element, valueAccessor) {
        ko.bindingHandlers.attr.update(element, function() {
            return { src: valueAccessor() };
        });
    }
};

ko.bindingHandlers.hidden = {
    update: function(element, valueAccessor) {
        var value = ko.utils.unwrapObservable(valueAccessor());
        ko.bindingHandlers.visible.update(element, function() { return !value; });
    }
};

ko.bindingHandlers.disabled = {
    update: function(element, valueAccessor) {
        var value = ko.utils.unwrapObservable(valueAccessor());
        ko.bindingHandlers.attr.update(element, function() {
            return { disabled: value };
        });
    }
};

ko.bindingHandlers.enabled = {
    update: function(element, valueAccessor) {
        var value = ko.utils.unwrapObservable(valueAccessor());
        ko.bindingHandlers.attr.update(element, function() {
            return { disabled: !value };
        });
    }
};

ko.bindingHandlers.toggle = {
    init: function(element, valueAccessor) {
        var value = valueAccessor();
        ko.applyBindingsToNode(element, {
            click: function() {
                value(!value());
            }
        });
    }
};

ko.bindingHandlers.placeholder = {
    init: function(element, valueAccessor, allBindingsAccessor) {
        var underlyingObservable = valueAccessor();
        ko.applyBindingsToNode(element, { attr: { placeholder: underlyingObservable } });
    }
};

// pausable observable subscriptions
ko.observable.fn.withPausing = function() {
    this.notifySubscribers = function() {
        if (!this.pauseNotifications) {
            ko.subscribable.fn.notifySubscribers.apply(this, arguments);
        }
    };

    this.sneakyUpdate = function(newValue) {
        this.pauseNotifications = true;
        this(newValue);
        this.pauseNotifications = false;
    };

    return this;
};

ko.observable.fn.increment = function (value) {
    this(this() + (value || 1));
};

ko.bindingHandlers.slideUp = {
    init : function(element, valueAccessor){
        var value = valueAccessor();
        $(element).toggle(ko.unwrap(value));
    },
    update : function(element, valueAccessor){
        var value = valueAccessor();
        ko.unwrap(value) > 0 ? $(element).show() : $(element).slideUp("slow") ;
    }
};