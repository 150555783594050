if (!String.prototype.format) {
    String.prototype.format = function() {
        var args = arguments;
        return this.replace(/{(\d+)}/g, function(match, number) {
            return typeof args[number] != "undefined" ? args[number] : match;
        });
    };
}

if (!String.prototype.startsWith) {
    String.prototype.startsWith = function(input) {
        return this.slice(0, input.length) == input;
    };
}

if (!String.prototype.endsWith) {
    String.prototype.endsWith = function(input) {
        return this.slice(-input.length) == input;
    };
}

if (!String.prototype.replaceAll) {
    String.prototype.replaceAll = function(find, replace) {
        return this.replace(new RegExp(find, 'g'), replace);
    };
}

if (!String.prototype.format) {
    String.prototype.format = function() { //fyi can only use each arg once
        var s = this,
            i = arguments.length;

        var argumentsArray = arguments;
        if (i == 1 && arguments[0].isArray) {
            i = arguments[i].length;
            argumentsArray = arguments[0];
        }

        while (i--) {
            s = s.replace(new RegExp('\\{' + i + '\\}', 'gm'), argumentsArray[i]);
        }

        return s;
    };
}

if (!String.prototype.splice) {
    String.prototype.splice = function(idx, rem, s) {
        return this.slice(0, idx) + s + this.slice(idx + Math.abs(rem));
    };
}

if (!String.prototype.isNullOrWhiteSpace) {
    String.prototype.isNullOrWhiteSpace = function() {
        return this === null || this.match(/^\s*$/) !== null;
    };
}

if (!String.prototype.trim) {
    String.prototype.trim = function() {
        return this.replace(/^\s+|\s+$/g, '');
    };
}

if (!String.prototype.addQuery) {
    String.prototype.addQuery = function(key, value) {
        var separator = this.indexOf("?") > -1 ? "&" : "?";
        var newUrl = this + separator + encodeURIComponent(key) + "=" + encodeURIComponent(value);
        return newUrl;
    };
}

if (!String.prototype.getQueryParameter) {
    String.prototype.getQueryParameter = function(parameterName) {
        var separatorIndex = this.indexOf("?");
        var queryString = this.substr(separatorIndex);
        return decodeURIComponent((new RegExp('[?|&]' + parameterName + '=' + '([^&;]+?)(&|#|;|$)').exec(queryString) || [, ""])[1].replace(/\+/g, '%20')) || null;
    };
}